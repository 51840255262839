import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { QrStatus } from "@/types/qr";
import { UserContextProps } from "@/contexts/UserContext/types";
import { GetProfileResponse } from "@/interface/api/getProfile/types";
import { SubscriptionStatus } from "@/types/subscription";
import { UserProps, UserStatus } from "@/types/user";

export const userProfileApiToDataTransformer = (profileData: GetProfileResponse): UserProps => {
  const profile = profileData;

  if (!profile) return undefined;

  return {
    accountId: profile.accountId,
    userId: profile.id,
    email: profile.email,
    firstName: profile.firstName,
    lastName: profile.lastName,
    phone: profile.phone,
    userStatus: UserStatus[profile.status?.toUpperCase()],
    freeTrialStartsAt: profile.freeTrialStartsAt,
    freeTrialEndsAt: profile.freeTrialEndsAt,
    provider: profile.provider,
    subscription: {
      id: profile.subscription.id,
      subscribed: profile.subscription?.subscribed,
      status: SubscriptionStatus[profile.subscription?.status?.toUpperCase()],
      payAt: profile.subscription?.payAt,
      unsubscribedAt: profile.subscription?.unsubscribedAt,
      requestedUnsubscribedAt: profile.subscription?.requestedUnsubscribedAt,
      plan: profile.subscription?.plan,
    },
    qrStatus:
      profile.subscription?.status?.toUpperCase() === SubscriptionStatus.ACTIVE
        ? QrStatus.ACTIVE
        : QrStatus.DRAFT,
  };
};

export const getRenewData = (userContext: UserContextProps) => {
  const payAt = userContext.user.subscription?.payAt;

  if (!payAt) return undefined;

  dayjs.extend(localizedFormat);
  const nextPaymentDate = dayjs(payAt).format("LL");

  return nextPaymentDate;
};

export const getCancelData = (user: UserProps) => {
  const unsubscriptionDate = user?.subscription?.unsubscribedAt;

  if (!unsubscriptionDate) return undefined;

  dayjs.extend(localizedFormat);
  const formattedDate = dayjs(unsubscriptionDate).format("LL");

  return formattedDate;
};

export const isSubscriptionActive = (userContext: UserContextProps) => {
  return (
    userContext?.user?.subscription?.status?.toLowerCase() ===
    SubscriptionStatus.ACTIVE.toLowerCase()
  );
};

export const isSubscriptionCancelled = (user: UserProps) => {
  return user?.subscription?.status?.toLowerCase() === SubscriptionStatus.CANCELLED.toLowerCase();
};

export const isSubscriptionRegistered = (userContext: UserContextProps) => {
  return (
    userContext?.user?.subscription?.status?.toLowerCase() ===
    SubscriptionStatus.REGISTERED.toLowerCase()
  );
};

export const isSubscriptionRecorded = (userContext: UserContextProps) => {
  return (
    userContext?.user?.subscription?.status?.toLowerCase() ===
    SubscriptionStatus.RECORDED.toLowerCase()
  );
};

export const isUserRegistered = (userContext: UserContextProps) => {
  return userContext?.user?.userStatus?.toLowerCase() === UserStatus.REGISTERED.toLowerCase();
};

export const isUserSubscribed = (userContext: UserContextProps) => {
  return userContext?.user?.userStatus?.toLowerCase() === UserStatus.SUBSCRIBED.toLowerCase();
};

export const isUserUnsubscribed = (userContext: UserContextProps) => {
  return userContext?.user?.userStatus?.toLowerCase() === UserStatus.UNSUBSCRIBED.toLowerCase();
};

export const isUserAnonymous = (userContext: UserContextProps) => {
  return userContext?.user?.userStatus?.toLowerCase() === UserStatus.ANONYMOUS.toLowerCase();
};

export const isUserNotAuthorized = (userContext: UserContextProps) => {
  return !!(isUserRegistered(userContext) && userContext.user.freeTrialEndsAt);
};

export const isUserFreeTrial = (userContext: UserContextProps) => {
  return userContext?.user?.userStatus?.toLowerCase() === UserStatus.TRIAL.toLowerCase();
};

export const userHasFreeTrialActive = (userContext: UserContextProps): boolean => {
  if (!userContext?.user?.freeTrialEndsAt) return;

  const freeTrialEndsAt = userContext?.user?.freeTrialEndsAt;

  const newDate = new Date(freeTrialEndsAt);
  const now = new Date();

  return dayjs(newDate).isAfter(now);
};

export const getRemainingFreeTrialDays = (userContext: UserContextProps): number => {
  if (!userContext?.user?.freeTrialEndsAt) return;
  const freeTrialEndsAt = userContext?.user?.freeTrialEndsAt;

  const newDate = new Date(freeTrialEndsAt);
  const now = new Date();

  return dayjs(newDate).diff(now, "days") + 1;
};

export const hasUserId = (userContext: UserContextProps) => {
  return userContext?.user?.userId;
};

export const nextPaymentDate = (user: UserProps) => {
  if (!user.subscription.payAt) return;
  dayjs.extend(localizedFormat);
  return dayjs(user.subscription.payAt).format("LL");
};

export const getUserSubscriptionCountry = (countryCode: string) => {
  let regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  return regionNames.of(countryCode);
};
